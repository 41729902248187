<template>
  <div class="content p-0">
    <div class="login-wrapper">
      <div class="row m-0">
        <div
          class="col-lg-4 side-banner"
          style="background-image:url('/static/images/bg-login.jpg');background-position: center center; background-size: cover;"
        >
          <div class="content px-4 text-center d-flex justify-content-center h-100">
            <div class="align-self-center">
              <!-- <img src="/static/images/thriggle.png" width="150" class="mb-4" alt=""> -->
              <h2 class="text-white">
                Thriggle
              </h2>
              <h4 class="text-white">
                Discover great places
              </h4>
              <p class="text-white">
                Find awesome bar & restaurant activities near you
              </p>
              <!-- <a href="JavaScript:Void(0)" class="btn btn-success">Search Now</a> -->
            </div>
          </div>
        </div>
        <div class="col-lg-8 site-content">
          <div class="content">
            <div class="row">
              <div class="col-lg-6 mx-auto">
                <div class="text-center mb-4">
                  <h2>Sign into Thriggle.</h2>
                  <!-- <p>Fill up the following details</p> -->
                </div>
                <LoginModal />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginModal from '../components/LoginModal.vue';
export default {
	components: {
		LoginModal,
	},
};
</script>
